<template>
  <v-container class="py-md-0 pt-md-6">
    <h2>{{ $t('cart.reload.title') }}</h2>
    <v-card flat>
      <v-container>
        <v-row class="d-flex my-sm-4 align-content-md-center">
          <v-col class="d-flex justify-md-center" lg="4" md="5" sm="4" cols="12" offset-lg="2"  offset-md="1">
            <v-img :src="tag?.reloadable_image" contain :max-height="isMobile ? 200 : undefined"/>
          </v-col>
          <v-col cols="12" lg="4" md="5" sm="8" class="align-content-center">
            <h2 class="mb-3">{{
                $options.filters.translatable($t('reloadTag.default_title'), tag.reloadable_title_i18n, $i18n.locale)
              }}</h2>
            <p>{{
                $options.filters.translatable($t('reloadTag.default_description'), tag.reloadable_description_i18n, $i18n.locale)
              }}</p>
            <p class="mt-8 mb-2 text-capitalize-fl">{{ $t('reloadTag.uid_number',{ media: mediaName}) }}</p>
            <v-text-field
                @keydown.enter="confirm"
                v-model="uid"
                :error-messages="uidError"
                autofocus
                dense
                outlined
                clearable/>
            <v-row>
              <v-col cols="12" md="6">
                <v-btn :disabled="loading" block outlined type="submit" @click="goBack">
                  <span v-text="$t('btn.back')"></span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn :disabled="loading || !uid" :loading="loading" block color="primary" @click="confirm">
                  <span v-text="$t('btn.confirm')"></span>
                </v-btn>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Page from '@/components/Page.vue'
import { EComService, EventBus } from '@connectngo/sdk'
import globalVariables from '@/global'

export default {
  name: 'ReloadUid',
  components: { Page },
  props: {
    tag: {
      type: Object,
      required: true,
    },
    mediaName : {
      type : String,
      required : true
    },
    isMobile : {
      type : Boolean,
      default : false
    }
  },
  data () {
    return {
      loading: false,
      uid: null,
      uidError: null,
      wallet : null,
      reloadProducts : [],
    }
  },
  created () {
    this.checkReloadWalletOnCart()
  },
  methods: {
    goBack () {
      return this.$router.push({ name: 'home'})
    },
    async confirm () {
      this.uidError = null
      this.loading = true
      try {
        await new EComService().getWalletFromUid(this.uid);
        let cart = await new EComService().attachReloadWalletToCart(globalVariables.cart.data.uuid, this.uid);
        const products = await new EComService().getReloadTagProducts(this.tag.slug, cart.data.uuid)
        this.reloadProducts = products.data.products;

        if(this.reloadProducts.length > 0 ) {
          globalVariables.cart = cart
          EventBus.publish('CART_UPDATED', cart)
          this.$emit('uidProductsUpdated', this.reloadProducts)
          this.$emit('walletUpdated', cart.data.reload_wallet)
          this.loading = false
        } else {
          // No product available for this wallet type
          this.uidError = this.$t('reloadTag.valid_uid_no_product_available',{ media: this.mediaName})
          await this.detachWallet();
          this.loading = false;
        }
      } catch (err) {
        if (err.response?.status === 404) {
          this.uidError = this.$t('reloadTag.invalid_uid',{ media: this.mediaName})
          if( globalVariables.cart.data.reload_wallet) {
            try {
              await this.detachWallet();
            } catch (e) {
              this.$handleError(this, e)
            }
          }
        } else {
          this.uidError = err.message
          this.$handleError(this, err)
        }
        this.loading = false;
      }
    },
    checkReloadWalletOnCart() {
      if(globalVariables.cart.data.reload_wallet_id) {
        this.uid = globalVariables.cart.data.reload_wallet.current_uid.uid;
      }
    },
    async detachWallet() {
      const cart = await new EComService().detachReloadWalletToCart(globalVariables.cart.data.uuid);
      globalVariables.cart = cart
      EventBus.publish('CART_UPDATED', cart)
      this.$emit('uidProductsUpdated', null)
      this.$emit('walletUpdated', cart.data.reload_wallet)
    },
  },

}
</script>

<style scoped>

</style>
