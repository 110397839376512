<template>
  <v-card style="border-radius: 8px" class="mb-4">
    <v-col class="relative">
      <v-img :src="productImg"
          :aspect-ratio="16 / 9"
          style="border-radius: 12px"
             max-height="300"
      />
      <v-chip
          v-if="promotion"
          color="secondaryLight"
          class="promotionChip px-4 py-0 overline ma-3"
          light
      >
        <span v-text="$t('product.promotion')"></span>
      </v-chip>
    </v-col>

    <v-card-title class="text-left pb-3">
      <span class="text-capitalize-fl">{{ $options.filters.translatable(product.data.name, product.data.name_i18n) }}</span>
      <CustomTooltip v-if="productShortDescription" :text="productShortDescription" icon="mdi-information-slab-circle-outline" position="bottom"/>
    </v-card-title>

    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col sm="6" md="8">
          <p class="mb-0 quantityLabel" v-if="!isUnlimitedAdmission">{{quantityLabel}} :</p>
          <p class="mb-0 quantityLabel" v-else>{{$t('reloadTag.one_reload_per_uid', {media: mediaName})}}</p>
          <p class="font-weight-bold subtitle-1 mb-0">
            <span v-if="product.data.msrp && parseFloat(product.data.msrp) > this.product.data.price" class="red--text text-decoration-line-through	">{{$options.filters.currency(product.data.msrp)}}</span>
            {{ $t('product.priceEach', { price : $options.filters.currency(this.product.data.price)}) }}
          </p>
        </v-col>
        <v-col sm="6" md="4">
          <InputNumericLogic
              v-if="!isUnlimitedAdmission"
              v-model="quantity"
              :increment="product.data.increment > 0 ? product.data.increment : 1"
              :has-error="hasError"
              :readonly="readonly"
              :min="_min"
              :max="_max"
              :product="product"
              :loading="loading"
              :hasQuantity="hasQuantity"
              large
          />
        </v-col>
      </v-row>

      <v-row class="d-flex align-center mt-0">
        <v-col sm="6" md="8">
          <p class="subtotal font-weight-bold h2 mb-0">{{$options.filters.currency(subTotal())}}</p>
        </v-col>
        <v-col sm="6" md="4" class="d-flex">
          <v-btn
              color="button"
              x-large
              block
              class="lowerCaseBtn"
              :disabled="unlimitedAdmissionAlreadyInCart"
              @click="addToCart"
              :loading="loading"
          >
            <span v-text="!unlimitedAdmissionAlreadyInCart ? $t('btn.addToCart') : $t('reloadTag.already_in_cart')"></span>
          </v-btn>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
import BackgroundImage from "@/assets/images/background.svg";
import { EventBus, ProductModel } from '@connectngo/sdk'
import ProductMixin, { InputNumericLogic } from '@/mixins/ProductMixin'
import CustomTooltip from '@/components/CustomTooltip.vue'
export default {
  name: 'ReloadProductCard',
  components: { CustomTooltip, InputNumericLogic },
  mixins: [ProductMixin],
  props: {
    product: {
      type: ProductModel,
      required: true,
    },
    mediaName : {
      type : String,
      required : true
    }
  },
  data() {
    return {
      unlimitedAdmissionAlreadyInCart : false,
      cartEvent : null,
      showTooltip : false,
    }
  },
  beforeMount () {
  },
  mounted () {
    this.checkUnlimitedAdmissionInCart();

    this.cartEvent = EventBus.subscribe("CART_UPDATED", (cart) => {
      this.checkUnlimitedAdmissionInCart()
    });
  },
  beforeDestroy() {
    this.cartEvent.unsubscribe();
  },
  computed : {
    productImg() {
      return this.product.data.image || BackgroundImage;
    },
    productShortDescription() {
      return this.product.data.short_description_i18n ? this.$options.filters.translatable(null, this.product.data.short_description_i18n) : null;
    },
    quantityLabel() {
      if (this._min === null && this._max === null) {
        return this.$t('product.quantity');
      } else if(this._min !== null && this._max === null) {
        return this.$t('product.quantityMin', {min : this._min})
      } else if(this._min === null && this._max !== null) {
        return this.$t('product.quantityMax', { max : this._max})
      } else {
        return this.$t('product.quantityBoth', {min : this._min, max : this._max})
      }
    },
    isUnlimitedAdmission() {
      return this.product.data.ticket_admission_type.includes('UNLIMITED')
    },
    productAlreadyInCartForSameWallet() {
      return this.$root.cart.data.items.some(item => {
        return item.data.product.data.id === this.product.data.id && item.data.reload_wallets.some(wallet => {
          return wallet.wallet_id === this.$root.cart.data.reload_wallet_id
        })
      })
    },
  },
  methods : {
    addToCart()  {
      this.loading = true
      if(this.unlimitedAdmissionAlreadyInCart) {
        return;
      }
      this.addProductToCartApiCall(!this.isUnlimitedAdmission ? this.quantity : 1)

    },
    checkUnlimitedAdmissionInCart() {
      this.unlimitedAdmissionAlreadyInCart =  !this.isUnlimitedAdmission ? false : this.productAlreadyInCartForSameWallet;
    }
  }
}
</script>

<style scoped lang="scss">
  .quantityLabel {
    font-size: 0.9rem;
  }
  p.subtotal {
    font-size: 1.75rem;
  }

  .promotionChip {
    position: absolute;
    top : 16px;
    left: 16px;
  }
</style>
