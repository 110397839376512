<template>
  <Page color="neutralLight" no-sidebar class="reloadPage">
      <template v-if="loading || !tag">
        <v-container class="py-md-0 pt-md-6">
          <v-skeleton-loader v-if="loading" type="card" />
        </v-container>
      </template>
      <template v-else>
        <ReloadUid v-if="step === 'uid'"
                   :tag="tag"
                   :media-name="mediaName"
                   :is-mobile="isMobile"
                   @walletUpdated="walletUpdated"
                   @uidProductsUpdated="uidProductsUpdated"/>

        <ReloadProducts v-else-if="step === 'products'"
                        :tag="tag" :wallet="this.wallet"
                        :tag-products="tagProducts"
                        :media-name="mediaName"
                        :is-mobile="isMobile"
                        @resetWallet="resetWallet" />
      </template>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import ReloadUid from '@/components/ReloadV2/ReloadUid.vue'
import ReloadProducts from '@/components/ReloadV2/ReloadProducts.vue'
import { EComService, EventBus } from '@connectngo/sdk'
import globalVariables from '@/global'

export default {
  name: "ReloadV2",
  components: { ReloadProducts, ReloadUid, Page },
  data() {
    return {
      loading: true,
      step : 'uid',
      tag : null,
      wallet : null,
      cartEvent : null,
      tagProducts : null,
      mediaName : null,
      isMobile : false,
    }
  },
  created () {
    this.mediaName = this.$options.filters.translatable(
        this.$root.websiteConfig.data.fields.media_name,
        this.$root.websiteConfig.data.fields.media_name_i18n,
        this.$i18n.locale
    ) || 'UID'
    if(this.$root.tags) {
      this.getReloadTag();
    } else {
      new EComService().getTags()
          .then(tagsResponse => {
            this.$root.tags = tagsResponse;
            this.getReloadTag();
          })
          .catch((reason) => this.$handleError(this, reason))
          .finally(() => (this.loading = false));
    }
  },
  beforeMount () {
    if (this.$route.params.step) {
      this.step = this.$route.params.step;
    }
  },
  mounted () {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    if(globalVariables.cart.data.id) {
      this.checkReloadWalletOnCart();
    } else {
      // Case refresh or access by URL and cart not loaded yet
      this.cartEvent = EventBus.subscribe('CART_UPDATED', () => {
        this.checkReloadWalletOnCart();
      })
    }

  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  methods : {
    getReloadTag() {
      this.tag = this.$root.tags.results.filter(tag => tag.data.reloadable)[0].data || null;
      if (!this.tag) {
        // If no reloadable tag redirect to home
        return this.$router.push({ name: 'home' });
      }
    },
    walletUpdated(wallet) {
      this.wallet = wallet;
      if(wallet) {
        this.$router.push({ name: 'reloadV2-step', params : {step : 'products'} })
      } else if(this.$route.params.step) {
        this.$router.push({ name: 'reloadV2' })
        this.step = 'uid';
      } else {
        this.step = 'uid';
      }
    },
    resetWallet() {
      this.loading = true;
      new EComService().detachReloadWalletToCart(globalVariables.cart.data.uuid).then(cart => {
        globalVariables.cart = cart;
        EventBus.publish('CART_UPDATED', cart)
        this.$router.push({ name: 'reloadV2' })
      }).catch(err => this.$handleError(this, err))
          .finally(() =>this.loading = false)
    },
    checkReloadWalletOnCart() {
      if(globalVariables.cart.data.reload_wallet_id) {
        this.wallet = globalVariables.cart.data.reload_wallet;
        if(this.cartEvent) {
          this.cartEvent.unsubscribe();
        }

        if(!this.$route.params.step) {
          this.$router.push({ name: 'reloadV2-step', params : {step : 'products'} })
        } else {
          this.step = 'products';
        }
      } else if(this.step === 'products') {
        // Product step without wallet
        this.$router.push({ name: 'reloadV2' })
      }

      this.loading = false;
    },
    uidProductsUpdated(products) {
      this.tagProducts = products;
    },
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
  }
}
</script>

<style scoped>
  ::v-deep .lowerCaseBtn {
    text-transform: none !important;
  }
</style>
