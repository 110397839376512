<template>
  <div>
    <v-container class="py-md-0 pt-md-6 position-relative">
      <v-skeleton-loader v-if="loading" type="card" />
      <template v-else>
        <v-row class="d-flex">
          <v-col cols="12" class="mb-3">
            <h2>{{$t('reloadTag.my_reload')}}</h2>
          </v-col>
          <v-col cols="12" md="4" >
            <v-img :src="tag?.reloadable_image" :max-height="!isMobile ? 400 : 200" contain/>
            <h3>{{$t('reloadTag.reloading_product_on_uid', {media: mediaName})}}</h3>
            <v-text-field solo readonly :value="wallet.current_uid.uid" class="mt-3 uidValue" />
          </v-col>
          <v-col cols="12" md="8" >
            <template v-if="products.length > 0">
              <ReloadProductCard v-for="product in products" :product="product" :media-name="mediaName" :key="product.data.id" />
            </template>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <div :class="['sticky-container py-4 systemLight',$root.cart.data.itemCount ? 'active' : '']">
      <v-row class="mx-1">
        <v-col offset-md="1" md="5">
          <v-btn
              color="button"
              outlined
              style="background-color: white"
              x-large
              block
              @click="resetWallet"
              class="lowerCaseBtn"
          >
            <v-icon left>mdi-account-multiple</v-icon>
            <span v-text="$t('reloadTag.add_another_uid', {media: mediaName})"></span>
          </v-btn>
        </v-col>
        <v-col md="5">
          <v-btn
              data-testid="checkoutBtn"
              color="button"
              :disabled="$root.cart.data.itemCount === 0"
              x-large
              block
              :to="{ name: 'cart' }"
              class="lowerCaseBtn"
          >
            <v-icon left>mdi-credit-card-outline</v-icon>
            <span v-text="$t('btn.proceedCheckout')"></span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page.vue'
import { EComService } from '@connectngo/sdk'
import globalVariables from '@/global'
import ReloadProductCard from '@/components/ReloadV2/ReloadProductCard.vue'

export default {
  name: "ReloadProducts",
  components: { ReloadProductCard, Page },
  props : {
    wallet : {
      type : Object,
      required : true
    },
    tag: {
      type: Object,
      required : true,
    },
    tagProducts : {
      type : Array | null,
      default : null
    },
    mediaName : {
      type : String,
      required : true
    },
    isMobile : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      loading : true,
      products : []
    }
  },
  mounted () {
    if(!this.tagProducts) {
      this.loadProducts();
    } else if(this.tagProducts.length === 0) {
      this.resetWallet()
    } else {
      this.products = this.tagProducts;
      this.loading = false;
    }
  },
  methods : {
    resetWallet() {
      this.$emit('resetWallet')
    },
    async loadProducts() {
      try {
        const data = await new EComService().getReloadTagProducts(this.tag.slug, globalVariables.cart.data.uuid)
        if(data.data.products === 0) {
          this.resetWallet()
          return;
        }
        this.products = data.data.products;
        this.loading = false;
      } catch (err) {
        this.$handleError(this, err)
      }
    }
  }
}
</script>

<style scoped>

.sticky-container {
  position: relative;
  background-color: #fff;
}

.sticky-container.active{
  position: sticky;
  position: -webkit-sticky;
  bottom: 0%;
  z-index: 2;
}

.sticky-container .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 620px) {
  .sticky-container .button-container button, .sticky-container .button-container a{
    font-size: 0.7rem;
    padding: 0 10px !important;
  }
}

@media all and (max-width: 430px) {
  .sticky-container .button-container {
    display: grid;
  }

  .sticky-container .button-container button{
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
</style>
