import Vue from "vue";

const ReloadV2Mixin =  Vue.extend({
    computed : {
        reloadableItems(): Array<any> {
            // @ts-ignore
            return this.$root.cart.data.items.filter(item => item.data.product.data.reloadable)
        },
        reloadItemTotal(): number {
            if(this.reloadableItems.length === 0) {
                return 0;
            }
            return this.reloadableItems.reduce((acc, item) => acc + item.data.quantity, 0);
        },
        reloadItemTotalAssigned(): number {
            if(this.reloadableItems.length === 0) {
                return 0;
            }
            return this.reloadableItems.reduce(
                (acc, item) => acc + item.data.reload_wallets.reduce(
                    (acc2, wallet) => acc2 + wallet.quantity, 0 )
                , 0);
        },
        allReloadableAssigned(): boolean {
            if(this.reloadableItems.length === 0) {
                return true;
            }
            return this.reloadItemTotal === this.reloadItemTotalAssigned
        },
        reloadV2FfEnabled() {
            // @ts-ignore
            const { feature_flags } = this.$root.websiteConfig.data || {};
            return feature_flags?.includes('co-7601-reload-flow-v2');
        },
        reloadV2Enabled() {
            // @ts-ignore
            return this.reloadV2FfEnabled && this.$root.websiteConfig.data.fields.active_reload === "1";
        }
    }
});

export default ReloadV2Mixin;