var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.product.data.event_group.id !== null)?_c('v-card',{staticClass:"mt-4",style:({
			borderColor: _vm.$vuetify.theme.themes.light.primary,
		}),attrs:{"outlined":""}},[_c('v-card-text',[_c('Calendar',{staticClass:"mb-3",attrs:{"product":_vm.product,"event-group-ids":_vm.eventGroupIds,"global-date":_vm.globalDate,"unavailableSlots":_vm.unavailableSlots},on:{"daySelect":_vm.onDaySelect},scopedSlots:_vm._u([{key:"prepend-month",fn:function(){return [_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$t('calendar.dateOfVisit'))}})]},proxy:true},{key:"prepend-day",fn:function(){return [_c('div',{staticClass:"title mt-3",domProps:{"textContent":_vm._s(_vm.$t('calendar.timeOfVisit'))}})]},proxy:true},{key:"append-day",fn:function(){return [_c('div',{staticClass:"title mt-3",domProps:{"textContent":_vm._s(_vm.$t('calendar.chooseYourItems'))}})]},proxy:true}],null,false,4192668699),model:{value:(_vm.selectedEventID),callback:function ($$v) {_vm.selectedEventID=$$v},expression:"selectedEventID"}}),(_vm.product.data.price !== null)?_c('InputNumericLogic',{attrs:{"title":_vm.$options.filters.translatable(
						_vm.product.data.name,
						_vm.product.data.name_i18n
					),"increment":_vm.product.data.increment > 0 ? _vm.product.data.increment : 1,"has-error":_vm.hasError,"readonly":_vm.readonly,"min":_vm._min,"max":_vm._max,"product":_vm.product,"loading":_vm.loading,"hasQuantity":_vm.hasQuantity,"large":""},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}):_c('v-row',{staticClass:"mt-4"},[_c('v-card-text',{staticClass:"text-left not_available red_text"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('product.notAvailable')))])])]),_c('v-card-text',{staticClass:"text-left not_available"},[_c('p',[_vm._v(_vm._s(_vm.$t('product.selectDifferentDate')))])])],1),_c('v-expand-transition',[(_vm.product.data.has_modifiers && _vm._eventId && _vm.product.data.price !== null)?_c('div',[(_vm.showEnhanceText)?_c('h4',{staticClass:"mt-8 mb-4 text-h5",domProps:{"textContent":_vm._s(_vm.enhanceText || _vm.$t('product.enhanceVisit'))}}):_vm._e(),(_vm.loadingModifiers)?_c('v-row',{attrs:{"dense":""}},_vm._l(([1, 4]),function(index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"image","height":"70"}})],1)}),1):_vm._l((_vm.modifiersByEventGroups),function(eventGroup,eventGroupIdx){return [_vm._l((eventGroup.data.modifiers.filter(
								(modifier) =>
									modifier.data.max_quantity ===
										modifier.data.min_quantity &&
									modifier.data.min_quantity > 0
							)),function(modifier,modifierIdx){return _c('div',[(
									_vm.selectedDay &&
									eventGroup.data.events !== null &&
									eventGroup.data.events.length > 0 &&
									eventGroup.data.events.length <= 4 &&
									!_vm.inCart
								)?_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},_vm._l((_vm.sortEvents(
										eventGroup.data.events,
										_vm.selectedDay
									)),function(slot){return _c('v-col',{key:slot.date,attrs:{"cols":"12","md":"6"}},[_c('CalendarSlot',{attrs:{"showAvailability":_vm.showAvailability,"period":slot.period,"remaining":slot.available,"total":slot.available,"color":_vm.selectedModifierEvent[
												eventGroup.guid
											] !== slot.id
												? 'secondaryDark'
												: 'primary',"selected":_vm.selectedModifierEvent[
												eventGroup.guid
											] === slot.id,"outlined":_vm.selectedModifierEvent[
												eventGroup.guid
											] !== slot.id,"disableSlot":_vm.checkDisabledSlot(
												slot.id,
												modifier.data.id
											)},on:{"slotMounded":function($event){return _vm.handleMountedSlot(
												slot,
												modifier.data
													.unavailable_before,
												modifier.data.id
											)},"click":function($event){return _vm.handleModifierSelectTimeSlotClick(
												slot,
												eventGroup.guid
											)}}})],1)}),1):(
									eventGroup.data.events !== null &&
									eventGroup.data.events.length > 0 &&
									eventGroup.data.events.length > 4 &&
									!_vm.inCart
								)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t('select.timeOfVisit'),"item-disabled":(options) =>
												_vm.isOptionDisabled(
													options,
													modifier.data.id
												),"items":_vm.selectDisplay(
												eventGroup,
												modifier.data
													.unavailable_before,
												modifier.data.id
											)},on:{"click":function($event){return _vm.handleItemMounted(eventGroup,modifier.data
													.unavailable_before,
												modifier.data.id)},"change":_vm.handleSelectTimeSlotSelect}})],1)],1):_vm._e(),(
									modifier.data.max_quantity ===
										modifier.data.min_quantity &&
									modifier.data.min_quantity > 0 &&
									modifier.data.status === 'ACTIVE'
								)?_c('Modifier',{class:{
									'top-border':
										modifierIdx === 0 &&
										_vm.showEnhanceText,
									'bottom-border': true,
								},attrs:{"value":modifier,"loading":_vm.loading,"readonly":_vm.readonly,"quantity":_vm.quantityModifiers[modifier.data.id],"has-error":_vm.hasQuantityWithoutSlot(
										modifier,
										eventGroup
									)},on:{"update:quantity":function($event){return _vm.$set(_vm.quantityModifiers, modifier.data.id, $event)}}}):_vm._e()],1)}),_vm._l((eventGroup.data.modifiers.filter(
								(modifier) =>
									modifier.data.max_quantity !==
									modifier.data.min_quantity
							)),function(modifier,modifierIdx){return (
								modifier.data.max_quantity !==
									modifier.data.min_quantity &&
								modifier.data.status === 'ACTIVE'
							)?_c('v-card',{key:modifier.data.id,class:{
								'elevation-2': true,
								'mt-3':
									eventGroupIdx > 0 || modifierIdx > 0,
							},attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"py-2"},[(
										eventGroup.data.events !== null &&
										eventGroup.data.events.length > 0 &&
										eventGroup.data.events.length <=
											4 &&
										!_vm.inCart
									)?_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},_vm._l((_vm.sortEvents(
											eventGroup.data.events,
											_vm.selectedDay
										)),function(slot){return _c('v-col',{key:slot.date,attrs:{"cols":"12","md":"6"}},[_c('CalendarSlot',{attrs:{"showAvailability":_vm.showAvailability,"period":slot.period,"remaining":slot.available,"total":slot.available,"color":_vm.selectedModifierEvent[
													eventGroup.guid
												] !== slot.id
													? 'secondaryDark'
													: 'primary',"selected":_vm.selectedModifierEvent[
													eventGroup.guid
												] === slot.id,"outlined":_vm.selectedModifierEvent[
													eventGroup.guid
												] !== slot.id,"disableSlot":_vm.checkDisabledSlot(
													slot.id,
													modifier.data.id
												)},on:{"slotMounded":function($event){return _vm.handleMountedSlot(
													slot,
													modifier.data
														.unavailable_before,
													modifier.data.id
												)},"click":function($event){return _vm.handleModifierSelectTimeSlotClick(
													slot,
													eventGroup.guid
												)}}})],1)}),1):(
										eventGroup.data.events !== null &&
										eventGroup.data.events.length > 0 &&
										eventGroup.data.events.length > 4 &&
										!_vm.inCart
									)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":_vm.$t('select.timeOfVisit'),"item-disabled":(options) =>
													_vm.isOptionDisabled(
														options,
														modifier.data.id
													),"items":_vm.selectDisplay(
													eventGroup,
													modifier.data
														.unavailable_before,
													modifier.data.id
												)},on:{"click":function($event){return _vm.handleItemMounted(eventGroup,modifier.data
														.unavailable_before,
													modifier.data.id)},"change":_vm.handleSelectTimeSlotSelect}})],1)],1):_vm._e(),_c('Modifier',{attrs:{"value":modifier,"loading":_vm.loading,"readonly":_vm.readonly,"selectable":modifier.data.checkbox,"required":_vm.infoModifiers[modifier.data.id]
											.required,"quantity":_vm.quantityModifiers[modifier.data.id],"selected":_vm.selectedModifiers[modifier.data.id],"has-error":_vm.hasQuantityWithoutSlot(
											modifier,
											eventGroup
										)},on:{"update:quantity":function($event){return _vm.$set(_vm.quantityModifiers, modifier.data.id, $event)},"update:selected":function($event){return _vm.$set(_vm.selectedModifiers, modifier.data.id, $event)},"click":function($event){return _vm.handleModifierClick(
											modifier.data.id
										)}}}),_c('v-expand-transition',[(
											_vm.hasQuantityWithoutSlot(
												modifier,
												eventGroup
											)
										)?_c('div',[_c('v-alert',{staticClass:"ma-0 mt-2",attrs:{"color":"error","text":"","dense":""}},[_c('span',{domProps:{"textContent":_vm._s(
													_vm.$tc(
														'product.error.mustSelectTimeSlot'
													)
												)}})])],1):_vm._e()])],1)],1):_vm._e()})]})],2):_vm._e()])],1)],1):(!_vm.skeleton)?[_c('InputNumericLogic',{attrs:{"title":_vm.$options.filters.translatable(
					_vm.product.data.name,
					_vm.product.data.name_i18n
				),"increment":_vm.product.data.increment > 0 ? _vm.product.data.increment : 1,"has-error":_vm.hasError,"readonly":_vm.readonly,"min":_vm._min,"max":_vm._max,"product":_vm.product,"loading":_vm.loading,"hasQuantity":_vm.hasQuantity,"large":""},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),(_vm.modifiersByEventGroups.length)?_c('div',[_c('v-divider',{staticClass:"my-4"}),_c('h4',{staticClass:"mb-4 title font-weight-light",domProps:{"textContent":_vm._s(
					_vm.enhanceText || _vm.$t('product.enhanceVisit')
				)}}),_vm._l((_vm.modifiersByEventGroups),function(localEventGroup,localEventGroupIdx){return _c('div',{key:localEventGroupIdx},_vm._l((localEventGroup.data.modifiers),function(modifier,modifierIdx){return _c('div',{key:modifierIdx},[_c('Modifier',{attrs:{"value":modifier,"loading":_vm.loading,"readonly":_vm.readonly,"selectable":modifier.data.checkbox,"required":_vm.infoModifiers[modifier.data.id]
								.required,"quantity":_vm.quantityModifiers[modifier.data.id],"selected":_vm.selectedModifiers[modifier.data.id],"has-error":_vm.hasQuantityWithoutSlot(
								modifier,
								localEventGroup
							)},on:{"update:quantity":function($event){return _vm.$set(_vm.quantityModifiers, modifier.data.id, $event)},"update:selected":function($event){return _vm.$set(_vm.selectedModifiers, modifier.data.id, $event)},"click":function($event){return _vm.handleModifierClick(
								modifier.data.id
							)}}})],1)}),0)})],2):_vm._e()]:_vm._e(),_c('v-divider',{staticClass:"mt-8 mb-6"}),_c('v-row',[(_vm.product.data.price !== null)?[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"title font-weight-bold",domProps:{"textContent":_vm._s(_vm.$t('product.subTotal'))}}),_vm._v(": ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(_vm.product.data.priceVerifiedViaApi || !_vm._eventId)?_c('div',{staticClass:"title font-weight-black",domProps:{"textContent":_vm._s(_vm.$options.filters.currency(_vm.totalPrice()))}}):_c('v-skeleton-loader',{attrs:{"type":"heading"}})],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.readonly && !_vm.inCart)?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canAddToCart(),"loading":_vm.loading,"block":"","x-large":""},on:{"click":_vm.handleAddClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart-plus")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.add'))}})],1):(!_vm.readonly)?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.canAddToCart(),"loading":_vm.loading,"block":"","x-large":""},on:{"click":_vm.handleApplyChanges}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart-plus")]),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.applyChanges'))}})],1):_vm._e()],1)],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }