<template>
	<div>
		<v-card
			class="mt-4"
			v-if="product.data.event_group.id !== null"
			:style="{
				borderColor: $vuetify.theme.themes.light.primary,
			}"
			outlined
		>
			<v-card-text>
				<Calendar
					:product="product"
					v-model="selectedEventID"
					class="mb-3"
					:event-group-ids="eventGroupIds"
					:global-date="globalDate"
					@daySelect="onDaySelect"
					:unavailableSlots="unavailableSlots"
				>
					<template v-slot:prepend-month>
						<div
							class="title"
							v-text="$t('calendar.dateOfVisit')"
						></div>
					</template>
					<template v-slot:prepend-day>
						<div
							class="title mt-3"
							v-text="$t('calendar.timeOfVisit')"
						></div>
					</template>
					<template v-slot:append-day>
						<div
							class="title mt-3"
							v-text="$t('calendar.chooseYourItems')"
						></div>
					</template>
				</Calendar>

				<!-- PRODUCT -->
				<InputNumericLogic
					v-if="product.data.price !== null"
					v-model="quantity"
					:title="
						$options.filters.translatable(
							product.data.name,
							product.data.name_i18n
						)
					"
					:increment="
						product.data.increment > 0 ? product.data.increment : 1
					"
					:has-error="hasError"
					:readonly="readonly"
					:min="_min"
					:max="_max"
					:product="product"
					:loading="loading"
					:hasQuantity="hasQuantity"
					large
				/>

				<v-row v-else class="mt-4">
					<v-card-text class="text-left not_available red_text">
						<p><strong>{{$t('product.notAvailable')}}</strong></p>
					</v-card-text>
					<v-card-text class="text-left not_available">
						<p>{{$t('product.selectDifferentDate')}}</p>
					</v-card-text>
				</v-row>

				<!-- MODIFIERS -->
				<v-expand-transition>
					<div v-if="product.data.has_modifiers && _eventId && product.data.price !== null">
						<h4
							class="mt-8 mb-4 text-h5"
							v-if="showEnhanceText"
							v-text="enhanceText || $t('product.enhanceVisit')"
						></h4>

						<v-row v-if="loadingModifiers" dense>
							<v-col
								cols="12"
								:key="index"
								v-for="index in [1, 4]"
							>
								<v-skeleton-loader
									type="image"
									height="70"
								></v-skeleton-loader>
							</v-col>
						</v-row>
						<template
							v-else
							v-for="(
								eventGroup, eventGroupIdx
							) in modifiersByEventGroups"
						>
							<div
								v-for="(
									modifier, modifierIdx
								) in eventGroup.data.modifiers.filter(
									(modifier) =>
										modifier.data.max_quantity ===
											modifier.data.min_quantity &&
										modifier.data.min_quantity > 0
								)"
							>
								<v-row
									v-if="
										selectedDay &&
										eventGroup.data.events !== null &&
										eventGroup.data.events.length > 0 &&
										eventGroup.data.events.length <= 4 &&
										!inCart
									"
									dense
									class="mt-4"
								>
									<v-col
										:key="slot.date"
										cols="12"
										md="6"
										v-for="slot in sortEvents(
											eventGroup.data.events,
											selectedDay
										)"
									>
										<CalendarSlot
											:showAvailability="showAvailability"
											:period="slot.period"
											:remaining="slot.available"
											:total="slot.available"
											:color="
												selectedModifierEvent[
													eventGroup.guid
												] !== slot.id
													? 'secondaryDark'
													: 'primary'
											"
											:selected="
												selectedModifierEvent[
													eventGroup.guid
												] === slot.id
											"
											:outlined="
												selectedModifierEvent[
													eventGroup.guid
												] !== slot.id
											"
											:disableSlot="
												checkDisabledSlot(
													slot.id,
													modifier.data.id
												)
											"
											@slotMounded="
												handleMountedSlot(
													slot,
													modifier.data
														.unavailable_before,
													modifier.data.id
												)
											"
											@click="
												handleModifierSelectTimeSlotClick(
													slot,
													eventGroup.guid
												)
											"
										/>
									</v-col>
								</v-row>
								<v-row
									v-else-if="
										eventGroup.data.events !== null &&
										eventGroup.data.events.length > 0 &&
										eventGroup.data.events.length > 4 &&
										!inCart
									"
									dense
								>
									<v-col cols="12">
										<v-select
											:label="$t('select.timeOfVisit')"
											:item-disabled="
												(options) =>
													isOptionDisabled(
														options,
														modifier.data.id
													)
											"
											:items="
												selectDisplay(
													eventGroup,
													modifier.data
														.unavailable_before,
													modifier.data.id
												)
											"
							   				@click="handleItemMounted(eventGroup,modifier.data
														.unavailable_before,
													modifier.data.id)"
											@change="handleSelectTimeSlotSelect"
										/>
									</v-col>
								</v-row>

								<Modifier
									v-if="
										modifier.data.max_quantity ===
											modifier.data.min_quantity &&
										modifier.data.min_quantity > 0 &&
										modifier.data.status === 'ACTIVE'
									"
									:value="modifier"
									:loading="loading"
									:readonly="readonly"
									:quantity.sync="
										quantityModifiers[modifier.data.id]
									"
									:has-error="
										hasQuantityWithoutSlot(
											modifier,
											eventGroup
										)
									"
									:class="{
										'top-border':
											modifierIdx === 0 &&
											showEnhanceText,
										'bottom-border': true,
									}"
								/>
							</div>
							<v-card
								:key="modifier.data.id"
								v-for="(
									modifier, modifierIdx
								) in eventGroup.data.modifiers.filter(
									(modifier) =>
										modifier.data.max_quantity !==
										modifier.data.min_quantity
								)"
								v-if="
									modifier.data.max_quantity !==
										modifier.data.min_quantity &&
									modifier.data.status === 'ACTIVE'
								"
								:class="{
									'elevation-2': true,
									'mt-3':
										eventGroupIdx > 0 || modifierIdx > 0,
								}"
								outlined
							>
								<v-card-text class="py-2">
									<!-- TIME SLOTS -->
									<v-row
										v-if="
											eventGroup.data.events !== null &&
											eventGroup.data.events.length > 0 &&
											eventGroup.data.events.length <=
												4 &&
											!inCart
										"
										dense
										class="mb-4"
									>
										<v-col
											:key="slot.date"
											cols="12"
											md="6"
											v-for="slot in sortEvents(
												eventGroup.data.events,
												selectedDay
											)"
										>
											<CalendarSlot
												:showAvailability="
													showAvailability
												"
												:period="slot.period"
												:remaining="slot.available"
												:total="slot.available"
												:color="
													selectedModifierEvent[
														eventGroup.guid
													] !== slot.id
														? 'secondaryDark'
														: 'primary'
												"
												:selected="
													selectedModifierEvent[
														eventGroup.guid
													] === slot.id
												"
												:outlined="
													selectedModifierEvent[
														eventGroup.guid
													] !== slot.id
												"
												:disableSlot="
													checkDisabledSlot(
														slot.id,
														modifier.data.id
													)
												"
												@slotMounded="
													handleMountedSlot(
														slot,
														modifier.data
															.unavailable_before,
														modifier.data.id
													)
												"
												@click="
													handleModifierSelectTimeSlotClick(
														slot,
														eventGroup.guid
													)
												"
											/>
										</v-col>
									</v-row>
									<v-row
										v-else-if="
											eventGroup.data.events !== null &&
											eventGroup.data.events.length > 0 &&
											eventGroup.data.events.length > 4 &&
											!inCart
										"
										dense
									>
										<v-col cols="12">
											<v-select
												:label="
													$t('select.timeOfVisit')
												"
												:item-disabled="
													(options) =>
														isOptionDisabled(
															options,
															modifier.data.id
														)
												"
												:items="
													selectDisplay(
														eventGroup,
														modifier.data
															.unavailable_before,
														modifier.data.id
													)
												"
							   				@click="handleItemMounted(eventGroup,modifier.data
															.unavailable_before,
														modifier.data.id)"
												@change="
													handleSelectTimeSlotSelect
												"
											/>
										</v-col>
									</v-row>

									<Modifier
										:value="modifier"
										:loading="loading"
										:readonly="readonly"
										:selectable="modifier.data.checkbox"
										:required="
											infoModifiers[modifier.data.id]
												.required
										"
										:quantity.sync="
											quantityModifiers[modifier.data.id]
										"
										:selected.sync="
											selectedModifiers[modifier.data.id]
										"
										:has-error="
											hasQuantityWithoutSlot(
												modifier,
												eventGroup
											)
										"
										@click="
											handleModifierClick(
												modifier.data.id
											)
										"
									/>

									<!-- HAS QUANTITY WITHOUT SLOT SELECTED -->
									<v-expand-transition>
										<div
											v-if="
												hasQuantityWithoutSlot(
													modifier,
													eventGroup
												)
											"
										>
											<v-alert
												color="error"
												class="ma-0 mt-2"
												text
												dense
											>
												<span
													v-text="
														$tc(
															'product.error.mustSelectTimeSlot'
														)
													"
												></span>
											</v-alert>
										</div>
									</v-expand-transition>
								</v-card-text>
							</v-card>
						</template>
					</div>
				</v-expand-transition>
			</v-card-text>
		</v-card>
		<template v-else-if="!skeleton">
			<InputNumericLogic
				v-model="quantity"
				:title="
					$options.filters.translatable(
						product.data.name,
						product.data.name_i18n
					)
				"
				:increment="
					product.data.increment > 0 ? product.data.increment : 1
				"
				:has-error="hasError"
				:readonly="readonly"
				:min="_min"
				:max="_max"
				:product="product"
				:loading="loading"
				:hasQuantity="hasQuantity"
				large
			/>

		<div v-if="modifiersByEventGroups.length">
			<v-divider class="my-4" />
				<h4
					class="mb-4 title font-weight-light"
					v-text="
						enhanceText || $t('product.enhanceVisit')
					"
				></h4>

				<div v-for="(localEventGroup, localEventGroupIdx) in modifiersByEventGroups" :key="localEventGroupIdx">
					<div v-for="(modifier, modifierIdx) in localEventGroup.data.modifiers" :key="modifierIdx">
						<Modifier
							:value="modifier"
							:loading="loading"
							:readonly="readonly"
							:selectable="modifier.data.checkbox"
							:required="
								infoModifiers[modifier.data.id]
									.required
							"
							:quantity.sync="
								quantityModifiers[modifier.data.id]
							"
							:selected.sync="
								selectedModifiers[modifier.data.id]
							"
							:has-error="
								hasQuantityWithoutSlot(
									modifier,
									localEventGroup
								)
							"
							@click="
								handleModifierClick(
									modifier.data.id
								)
							"
						/>
					</div>
				</div>
			</div>
		</template>

		<v-divider class="mt-8 mb-6" />

		<!-- TOTAL -->
		<v-row>
			<template v-if="product.data.price !== null">
				<v-col cols="6">
				<span
					class="title font-weight-bold"
					v-text="$t('product.subTotal')"
				></span
				>:
				</v-col>
				<v-col cols="6" class="text-right">
					<div v-if="product.data.priceVerifiedViaApi || !_eventId"
						 class="title font-weight-black"
						 v-text="$options.filters.currency(totalPrice())"
					></div>
					<v-skeleton-loader v-else type="heading" />
				</v-col>
			</template>

			<v-col cols="12">
				<v-btn
					v-if="!readonly && !inCart"
					color="primary"
					:disabled="!canAddToCart()"
					:loading="loading"
					block
					x-large
					@click="handleAddClick"
				>
					<v-icon left>mdi-cart-plus</v-icon>
					<span v-text="$t('btn.add')"></span>
				</v-btn>
				<v-btn
					v-else-if="!readonly"
					color="primary"
					:disabled="!canAddToCart()"
					:loading="loading"
					block
					x-large
					@click="handleApplyChanges"
				>
					<v-icon left>mdi-cart-plus</v-icon>
					<span v-text="$t('btn.applyChanges')"></span>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import ProductMixin, { InputNumericLogic, DebounceMixin } from "@/mixins/ProductMixin";
import Modifier from "@/components/Modifier";
import Calendar from "@/components/Calendar";
import CalendarSlot from "@/components/CalendarSlot";
import globalVariables from "@/global";
import moment from "moment";

export default {
	name: "ProductCard",

	mixins: [ProductMixin, DebounceMixin],

	components: { Calendar, CalendarSlot, InputNumericLogic, Modifier },

	props: {
		eventGroupIds: {
			type: Array,
			default: () => [],
		},
		globalDate: {
			type: String,
			default: "",
		},
	},

	data: () => ({
		modifierMax1Min1Length: 0,
		unavailableSlots: [],
		disabledSlot: [],
	}),
	watch: {
		loading(val) {
			this.$emit("onProductLoading", val);
		},
	},
	destroyed() {
		this.$emit("onProductLoading", false);
	},
	computed: {
		showEnhanceText() {
			let result;
			if (
				this.modifiersByEventGroups &&
				this.modifiersByEventGroups.length > 0
			) {
				this.modifiersByEventGroups.forEach((eventGroups) => {
					if (
						eventGroups.data.modifiers.length === 1 &&
						eventGroups.data.modifiers[0].data.max_quantity ===
							eventGroups.data.modifiers[0].data.min_quantity
					) {
						result = false;
					} else if (
						eventGroups.data.modifiers.length === 1 &&
						eventGroups.data.modifiers[0].data.max_quantity !==
							eventGroups.data.modifiers[0].data.min_quantity
					) {
						result = true;
					} else if (
						eventGroups.data.modifiers.length > 1 &&
						eventGroups.data.modifiers.find(
							(modifier) =>
								modifier.data.max_quantity !==
								modifier.data.min_quantity
						)
					) {
						result = true;
					} else {
						result = false;
					}
				});
				return result;
			}
		},
		showAvailability() {
			return (
				parseInt(
					globalVariables.websiteConfig.data.fields
						.show_tickets_availabilities
				) ? true : false
			);
		},
	},

	created() {
		this.load();
	},

	methods: {
		handleItemMounted(eventGroup,un,slot) {
			if (eventGroup.data.events?.length > 0) {
				this.debounce(() => {
					const events = this.sortEvents(
						eventGroup.data.events,
						this.selectedDay
					)
					events.map(item => {
						if (!this.disabledSlot.includes(item.id)) {
							this.handleMountedSlot(item, un, slot)
						}
					})
				}, 500)
			}
		},
		isOptionDisabled(option, modifierId) {
			return this.checkDisabledSlot(option.value.slot.id, modifierId);
		},
		checkDisabledSlot(slotId, modifierId) {
			if (this.disabledSlot.length > 0) {
				const slot = this.disabledSlot.find(
					(data) =>
						data.slotId === slotId && data.modifierID === modifierId
				);
				if (slot) {
					return false;
				}
				return true;
			}
		},
		onDaySelect(day) {
			this.selectedDay = day;
		},
		hasQuantityWithoutSlot(modifier, eventGroup) {
			return (
				this.quantityModifiers[modifier.data.id] > 0 &&
				!this.selectedModifierEvent[eventGroup.guid] &&
				eventGroup.data.events !== null &&
				eventGroup.data.events.length > 0 &&
				!this.inCart
			);
		},
		selectDisplay(eventgroup, unavailable, slot) {
			if (
				!eventgroup.data.events ||
				eventgroup.data.events.length === 0
			) {
				return [];
			}
			const events = this.sortEvents(
				eventgroup.data.events,
				this.selectedDay
			);

			return events.map((item) => {
				let label = item.period;
				if (this.showAvailability) {
					label += ` (${this.$tc("calendar.places", item.available, {
						amount: item.available,
					})})`;
				}

				return {
					value: { slot: item, guid: eventgroup.guid },
					disabled: item.available <= 0,
					text: label,
				};
			});
		},
		load() {
			const unavailableProd = {
				unavailable_before: this.product.data.unavailable_before,
				eventGroupId: this.product.data.event_group.id,
				isUnavailableBefore: false
			};
			this.unavailableSlots = [unavailableProd];
		},
		handleMountedSlot(slot, unavailableBefore, id) {
			if (
				!unavailableBefore &&
				!this.product.data.unavailable_before
			) {
				this.disabledSlot.push({ slotId: slot.id, modifierID: id })
				return
			}

			let disabledSlotTest
			const slotMoment = this.dateToTenantTimezone(
				new Date(slot.start_ts * 1000)
			)
			if (unavailableBefore) {
				const unavailableBeforeMoment = this.dateToTenantTimezone(
					new Date(unavailableBefore * 1000)
				)
				const unavailableBeforeIsAfterProduct =
					unavailableBeforeMoment.isAfter(
						moment.unix(this.product.data.unavailable_before)
					)
				if (!unavailableBeforeIsAfterProduct) {
					disabledSlotTest = slotMoment.isAfter(
						moment.unix(this.product.data.unavailable_before)
					)
				} else {
					disabledSlotTest = slotMoment.isAfter(
						moment.unix(unavailableBefore)
					)
				}
			} else {
				disabledSlotTest = slotMoment.isAfter(
					moment.unix(this.product.data.unavailable_before)
				)
			}
			if (disabledSlotTest) {
				this.disabledSlot.push({ slotId: slot.id, modifierID: id })
			}
			this.disabledSlot = [...new Set(this.disabledSlot)];
		},
		handleSelectTimeSlotSelect(data) {
			this.handleModifierSelectTimeSlotClick(data.slot, data.guid);
		},
	},
};
</script>

<style lang="scss" scoped>
.v-application .title {
	font-size: 1rem !important;
}

.not_available {
	font-size: 18px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.red_text {
	color: rgba(207, 65, 65, 1);
}
</style>
